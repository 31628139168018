import SearchInput from '@theme/components/shop/SearchInput'

export default {
  components: {
    SearchInput,
  },
  methods: {
    closeSearch() {
      this.$store.commit('search/CLOSE_HEADER_SEARCH_INPUT')
    },
  },
  mounted() {
    if (this.$route.name && this.$route.name.indexOf('search__') !== 0) {
      this.$store.commit('search/CLOSE_HEADER_SEARCH_INPUT')
    }
  },
}
