var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"HeaderSearch",class:[
    {'HeaderSearch--open': _vm.$store.state.search.headerSearchInputOpen},
    _vm.$themeSettings.components.HeaderSearch.breakpoint.hide
      ? `d-${_vm.$themeSettings.components.HeaderSearch.breakpoint.hide}-none`
      : '',
    _vm.$themeSettings.components.HeaderSearch.breakpoint.show
      ? `d-${_vm.$themeSettings.components.HeaderSearch.breakpoint.show}-flex`
      : '',
  ]},[_c('div',{staticClass:"HeaderSearch__inputWrapper"},[_c('SearchInput',{attrs:{"input-id":'HeaderSearchInput',"autofocus":_vm.$store.state.search.headerSearchInputOpen}})],1),(_vm.$themeSettings.components.HeaderSearch.close.enable)?_c('div',{staticClass:"HeaderSearch__close",on:{"click":_vm.closeSearch}},[_c('svgicon',{attrs:{"icon":require('@icon/cross.svg?raw')}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }